import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

const Header = () => {
  const [originalTitle, setOriginalTitle] = useState();

  const handleTabChange = () => {
    if (document.hidden) {
      document.title = 'Grįžk reklamos!';
    } else {
      document.title = originalTitle;
    }
  };

  useEffect(() => {
    if (!originalTitle) {
      setOriginalTitle(document.title);
    }

    window.addEventListener('visibilitychange', handleTabChange);
    return () => window.removeEventListener('visibilitychange', handleTabChange);
  });

  return (
    <header id="header" className="alt">
      <Link to="/" className="logo"><strong>Velgama</strong></Link>
    </header>
  );
};

export default Header;
