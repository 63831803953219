import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const Contact = () => {
  const [state, setState] = React.useState({});
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const handleFormSubmit = (form) => {
    form.reset();
    openDialog();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.reportValidity) {
      return;
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => handleFormSubmit(form))
      .catch((error) => alert('Išsiųsti žinutės nepavyko. Perkraukite puslapį ir bandykite dar kartą'));
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <section id="contact">
      <div className="inner">
        <section>
          <form
            name="contact-us"
            method="post"
            data-netlify="true"
            action="/"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-us" />
            <div className="field half first">
              <label htmlFor="name">
                  Vardas
                <input type="text" name="name" id="name" onChange={handleChange} />
              </label>
            </div>
            <div className="field half">
              <label htmlFor="email">
                  El. paštas
                <input required type="email" name="email" id="email" onChange={handleChange} />
              </label>
            </div>
            <div className="field">
              <label htmlFor="message">
                  Žinutė
                <textarea required name="message" id="message" rows="6" onChange={handleChange} />
              </label>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Siųsti žinutę" className="special" /></li>
              <li><input type="reset" value="Išvalyti" /></li>
            </ul>
          </form>
        </section>
        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope" />
              <a href="mailto:velgamainfo@gmail.com">velgamainfo@gmail.com</a>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-phone" />
              <span><a href="tel:+370 686 422 34">+370 686 422 34</a></span>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-home" />
              <h3>Mus galite rasti</h3>
              <span>
                <a href="https://goo.gl/maps/58cmqXTrwNbMz6N6A" target="_blank" rel="noreferrer noopener">
                  Nemuno g. 16A
                  <br />
                        Kaunas
                  <br />
                  (Įėjimas iš Smalininkų gatvės į kiemą)
                </a>
              </span>
            </div>
          </section>
        </section>
      </div>
      <SweetAlert success show={showDialog} title="Pranešimas išsiųstas!" onConfirm={closeDialog} confirmBtnText="Gerai">
          Netrukus su Jumis susisieksime
      </SweetAlert>
    </section>
  );
};

export default Contact;
