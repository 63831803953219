import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ onToggleMenu }) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links" />
      <ul className="actions vertical" />
    </div>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
};

export default Menu;
